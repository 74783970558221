// external
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Paper, Skeleton, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { GuideRegistrationCard, PageCard } from 'components';

// store
import { useAppSelector } from 'store/hooks';
import { selectMembershipGuideForm } from 'store/slices/formsSlice';

// hooks
import {
  useCustomFields,
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
  useProfiles,
  useSkills,
  useUsers,
} from 'hooks';

// types
import {
  EProgramVariation,
  ICustomField,
  IMembership,
} from '@guider-global/shared-types';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';

export const GuideRegistrationPreviewPage: React.FC = () => {
  // Utils
  const organizationSlug = getSubDomain();

  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // React router
  const navigate = useNavigate();
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  // Internal hooks
  // Users
  const { users } = useUsers({ getSilently: false });
  const user = users().at(0);
  // Profiles
  const { getProfiles } = useProfiles({ getSilently: true });
  const [profile] = getProfiles();
  const profilePicture = profile?.picture;
  // Memberships
  const { isLoadingMemberships, reqMemberships } = useMemberships({});
  // Skills
  const { isLoadingSkills } = useSkills({});
  // Custom fields
  const { reqCustomFields, isLoadingCustomFields } = useCustomFields({});
  // Programs
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);
  // Events
  const { trackMixpanelEvent, unionUserProperty } = useMixpanelEvents();

  // Redux
  const membershipGuideForm = useAppSelector((state) =>
    selectMembershipGuideForm(state, programSlug),
  );

  const isGuide = role === 'guide';

  useEffect(() => {
    if (!isGuide) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isGuide, navigate, programSlug]);

  if (!program || !role) {
    return <></>;
  }

  const programTypeText =
    program?.program_details?.program_type?.program_type_text;
  const reviewProfile =
    programTypeText?.variations?.individual?.registration?.registration_guide
      ?.registration_guide_review_profile;
  const requiresApproval =
    program.registration?.guide_membership_approval ?? false;

  const publishProfile = async () => {
    let customFields: ICustomField[] = [];

    const customFieldsPostData: Partial<ICustomField>[] = Object.entries(
      membershipGuideForm,
    )
      .filter(
        ([fieldKey]) =>
          fieldKey !== 'skills' && fieldKey !== 'goalCategorySlugs',
      )
      .map(([fieldKey, fieldValue]) => {
        const fieldType = program?.registration?.registration_questions?.find(
          (registrationQuestion) =>
            registrationQuestion.id.current === fieldKey,
        )?.type;

        return {
          fieldSlug: fieldKey,
          organizationSlug,
          programSlug: programSlug,
          fieldType,
          value: fieldValue,
          profileId: profile.id,
        };
      });

    if (customFieldsPostData.length > 0) {
      const customFieldsResponse = await reqCustomFields({
        method: 'POST',
        url: '/customfields',
        data: customFieldsPostData,
      });

      Array.isArray(customFieldsResponse.data) &&
        (customFields = customFieldsResponse.data);
    }

    const customFieldIds: string[] | undefined = customFields.map(
      (customField) => customField.id,
    );

    const membershipData: Partial<IMembership> & {
      goalCategorySlugs: string[];
      skillSlugs: string[];
    } = {
      role: 'guide',
      organizationSlug,
      programSlug,
      profile: profile.id,
      isPublished: true,
      programFields: customFieldIds ?? [],
      skillSlugs: membershipGuideForm?.skills ?? [],
      requiresApproval,
      programVariationTypeSlug: EProgramVariation.Individual,
      goalCategorySlugs:
        (membershipGuideForm.goalCategorySlugs as string[]) ?? [],
    };

    await reqMemberships({
      method: 'POST',
      url: '/memberships',
      data: membershipData,
    });

    await reqMemberships({ url: '/memberships' });
    if (requiresApproval) {
      navigate('./../membership-pending');
    } else {
      navigate('./../published');
    }
  };

  const handlePublishProfileButtonClick = async () => {
    try {
      await publishProfile();
      trackMixpanelEvent('Guide Program Registration - Profile published', {
        'Program type': programTypeText?.common?.verb,
        'Program ID': programSlug,
      });
      unionUserProperty(
        'Guide profiles',
        `${organizationSlug}-${programTypeText?.common?.verb}`,
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={reviewProfile?.registration_guide_review_profile_title ?? ''}
        subtitle={
          reviewProfile?.registration_guide_review_profile_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      {profile ? (
        <GuideRegistrationCard
          guide={{
            organizationFields: profile.organizationFields,
            displayName: profile.displayName,
            firstName: profile.firstName,
            lastName: profile.lastName,
            jobTitle: profile.jobTitle,
            townOrCity: profile.townOrCity,
            linkedInUrl: profile.linkedInUrl,
            pictures: {
              profile: profilePicture,
              user: user?.picture,
            },
          }}
          data={membershipGuideForm}
          sx={{ mx: isMobile ? 2 : 3 }}
        />
      ) : (
        <Skeleton height={200} sx={{ transform: 'none' }} />
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          mt: 3,
          px: isMobile ? 2 : 3,
        }}
      >
        <Button
          data-cy="pages-ProgramRegistrationPage-Guide-GuideRegistrationPreviewPage_navigation-button"
          variant="outlined"
          color="info"
          size="large"
          fullWidth={isMobile}
          onClick={() => navigate('./../')}
        >
          {baseLanguage.globals?.common?.edit_profile_button_label}
        </Button>
        <LoadingButton
          data-cy="pages-ProgramRegistrationPage-Guide-GuideRegistrationPreviewPage_submit-button"
          variant="contained"
          color="info"
          size="large"
          type="submit"
          sx={{
            mb: isMobile ? 2 : 0,
          }}
          fullWidth={isMobile}
          onClick={handlePublishProfileButtonClick}
          loading={
            isLoadingMemberships() || isLoadingSkills || isLoadingCustomFields()
          }
        >
          {
            reviewProfile?.registration_guide_review_profile_publish_profile_button_label
          }
        </LoadingButton>
      </Box>
    </Paper>
  );
};
