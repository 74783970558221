import {
  useBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  AvatarBox,
  Button,
  Checkbox,
  Loading,
  TextStack,
} from '@guider-global/ui';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import guideIcon from 'assets/users.svg';
import traineeIcon from 'assets/learn.svg';
import { useEffect, useState } from 'react';
import { ISkill, ProfileSkill } from '@guider-global/shared-types';
import { useLocalization, useMemberships } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressContainer } from 'containers';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { getSubDomain } from '@guider-global/front-end-utils';

export function ProgramSkillsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const [showMoreSoftSkills, setShowMoreSoftSkills] = useState<boolean>(false);
  const [showMoreHardSkills, setShowMoreHardSkills] = useState<boolean>(false);

  const [selectedHardSkills, setSelectedHardSkills] = useState<ProfileSkill[]>(
    [],
  );
  const [selectedSoftSkills, setSelectedSoftSkills] = useState<ProfileSkill[]>(
    [],
  );

  const [maxError, setMaxError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Program
  const { getProgram, isLoadingSanityPrograms } = useSanityOrganizationPrograms(
    {},
  );
  const program = getProgram(programSlug);

  // Skill limits
  const min =
    program?.registration?.skills?.multi_select_config?.minimum_selections ?? 1;
  const max =
    program?.registration?.skills?.multi_select_config?.maximum_selections ??
    undefined;

  const selectedSkills =
    selectedSoftSkills?.length + selectedHardSkills?.length;

  useEffect(() => {
    if (max && selectedSkills === max) {
      setMaxError(true);
    } else {
      setMaxError(false);
    }
  }, [selectedSkills, max]);

  // Memberships
  const { memberships, reqMemberships, isLoadingMemberships } = useMemberships({
    getSilently: false,
    forceRefresh: true,
  });

  const membership = memberships().find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === role,
  );

  const hardSkills = program?.registration?.skills?.hard_skills ?? [];
  const softSkills = program?.registration?.skills?.soft_skills ?? [];

  useEffect(() => {
    if (membership?.skills) {
      const existingSkillSlugs =
        (membership?.skills as ISkill[])?.map((skill) => skill?.fieldSlug) ??
        [];

      const existingHardSkills =
        hardSkills?.filter(
          ({ slug }) => slug && existingSkillSlugs?.includes(slug),
        ) ?? [];

      const existingSoftSkills =
        softSkills?.filter(
          ({ slug }) => slug && existingSkillSlugs?.includes(slug),
        ) ?? [];

      setSelectedHardSkills(existingHardSkills);
      setSelectedSoftSkills(existingSoftSkills);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership?.skills]);

  async function handleSkillsSubmit() {
    if (!membership || !role) return;
    setLoading(true);

    const skillsData: Partial<ISkill> & {
      hardSkillSlugs?: string[];
      softSkillSlugs?: string[];
      role: 'trainee' | 'guide';
    } = {
      role,
      programSlug,
      hardSkillSlugs: selectedHardSkills?.map(
        (skill) => skill.slug,
      ) as string[],
      softSkillSlugs: selectedSoftSkills?.map(
        (skill) => skill.slug,
      ) as string[],
    };

    const result = await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${membership?.id}`,
      data: {
        isPublished: membership?.isPublished,
        ...skillsData,
      },
    });

    if (result.status === 'success' && result.data) {
      if (role === 'guide') {
        navigate('../preview');
      } else {
        navigate('../choose');
      }
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: result?.message,
          timeout: 5000,
        }),
      );
    }
    setLoading(false);
  }

  function isSkillSelected(skill: ProfileSkill) {
    return [...selectedSoftSkills, ...selectedHardSkills].find(
      (s) => s?.slug === skill?.slug,
    )
      ? true
      : false;
  }

  const toggleSoftSkillSelect = (skill: ProfileSkill) => {
    isSkillSelected(skill)
      ? setSelectedSoftSkills([
          ...selectedSoftSkills?.filter(
            (selected) => selected?.slug !== skill?.slug,
          ),
        ])
      : setSelectedSoftSkills([...selectedSoftSkills, skill]);
  };
  const toggleHardSkillSelect = (skill: ProfileSkill) => {
    isSkillSelected(skill)
      ? setSelectedHardSkills([
          ...selectedHardSkills?.filter(
            (selected) => selected?.slug !== skill?.slug,
          ),
        ])
      : setSelectedHardSkills([...selectedHardSkills, skill]);
  };

  if (!baseLanguage || !role)
    return (
      <ProgressContainer percentage={75}>
        <></>
      </ProgressContainer>
    );

  const isLoading = isLoadingMemberships() || isLoadingSanityPrograms();

  if (isLoading && !loading) {
    return (
      <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={75}>
        <Loading color="secondary" />
      </ProgressContainer>
    );
  }

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={75}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
      >
        <AvatarBox
          orientation="vertical"
          textSpacing={1.5}
          size="medium"
          heading={{
            text: program?.registration?.skills?.[role]?.question_title,
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: program?.registration?.skills?.[role]?.question_description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
            program?.registration?.skills?.multi_select_config
              ?.select_skills_number_description && {
              text: program?.registration?.skills?.multi_select_config
                ?.select_skills_number_description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
          avatarSrc={role === 'guide' ? guideIcon : traineeIcon}
          avatarSx={{
            p: 1.25,
            border: 'none',
            backgroundColor: '#F5F5F5',
          }}
        />

        <Stack direction={'column'} pt={2}>
          <TextStack
            size="xs"
            heading={
              baseLanguage?.registration?.open_matching?.skills
                ?.soft_skill_label
            }
            tooltipText={
              baseLanguage?.registration?.open_matching?.skills
                ?.soft_skill_tooltip
            }
          />
          {softSkills &&
            softSkills?.length > 0 &&
            softSkills
              ?.slice(0, showMoreSoftSkills ? undefined : 5)
              ?.map((skill, index) => {
                return (
                  <Checkbox
                    key={`${skill?.slug}-soft`}
                    variant="option"
                    heading={skill?.label}
                    onChange={() => toggleSoftSkillSelect(skill)}
                    isChecked={isSkillSelected(skill) ?? false}
                    data-cy={`register-soft-skill-${index}`}
                    disabled={maxError && !isSkillSelected(skill)}
                  />
                );
              })}
          <Button
            sx={{
              display: softSkills?.length > 5 ? '' : 'none',
            }}
            variant="text"
            label={
              showMoreSoftSkills
                ? baseLanguage?.globals?.common?.show_less_button_label ??
                  'Less'
                : baseLanguage?.globals?.common?.show_more_button_label ??
                  'More'
            }
            endIcon={showMoreSoftSkills ? <ArrowDropUp /> : <ArrowDropDown />}
            onClick={() => setShowMoreSoftSkills(!showMoreSoftSkills)}
            data-cy="soft-skills-show-more-less-button"
          />
        </Stack>

        <Stack direction={'column'} pt={2}>
          <TextStack
            size="xs"
            heading={
              baseLanguage?.registration?.open_matching?.skills
                ?.hard_skill_label
            }
            tooltipText={
              baseLanguage?.registration?.open_matching?.skills
                ?.hard_skill_tooltip
            }
          />
          {hardSkills &&
            hardSkills?.length > 0 &&
            hardSkills
              ?.slice(0, showMoreHardSkills ? undefined : 5)
              ?.map((skill, index) => {
                return (
                  <Checkbox
                    key={`${skill?.slug}-hard`}
                    variant="option"
                    heading={skill?.label}
                    onChange={() => toggleHardSkillSelect(skill)}
                    isChecked={isSkillSelected(skill) ?? false}
                    data-cy={`register-hard-skill-${index}`}
                    disabled={maxError && !isSkillSelected(skill)}
                  />
                );
              })}
          <Button
            sx={{
              display: hardSkills?.length > 5 ? '' : 'none',
            }}
            variant="text"
            label={
              showMoreHardSkills
                ? baseLanguage?.globals?.common?.show_less_button_label ??
                  'Less'
                : baseLanguage?.globals?.common?.show_more_button_label ??
                  'More'
            }
            endIcon={showMoreHardSkills ? <ArrowDropUp /> : <ArrowDropDown />}
            onClick={() => setShowMoreHardSkills(!showMoreHardSkills)}
            data-cy="hard-skills-show-more-less-button"
          />
        </Stack>
        <Button
          variant="contained"
          label={baseLanguage?.globals?.common?.continue_button_label}
          color="info"
          onClick={handleSkillsSubmit}
          disabled={min > selectedSkills}
          data-cy="register-skills-continue-button"
          loading={loading}
        />
      </Stack>
    </ProgressContainer>
  );
}
