// external
import React, { useEffect } from 'react';
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PersonPinSharp from '@mui/icons-material/PersonPinSharp';

// components
import { PageCard } from 'components';

// internal
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';

export const GuideRegistrationPendingPage: React.FC = () => {
  // React Router
  const navigate = useNavigate();
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  // Programs
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  // Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const isGuide = role === 'guide';

  useEffect(() => {
    if (!isGuide) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isGuide, navigate, programSlug]);

  if (!program || !role) {
    return <></>;
  }

  const programType = program.program_details?.program_type;
  const programVariation =
    programType?.program_type_text?.variations?.individual?.registration
      ?.registration_guide;
  const membershipPending =
    programVariation?.registration_guide_membership_pending;

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={membershipPending?.title ?? ''}
        subtitle={membershipPending?.description ?? ''}
        color="warning"
        icon={PersonPinSharp}
        textAlign={isMobile ? 'center' : 'start'}
      />
      <Typography
        variant="body2"
        sx={{
          mt: { xs: 3, md: 5 },
          mb: { xs: 3, md: 2 },
          px: { xs: 2, md: 3 },
          textAlign: { xs: 'center', md: 'start' },
        }}
      >
        {membershipPending?.actions_label}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          px: { xs: 2, md: 3 },
        }}
      >
        <Button
          data-cy="pages-ProgramRegistrationPage-Guide-GuideRegistrationPublishedPage_programs-button"
          variant="contained"
          color="info"
          size="large"
          fullWidth={isMobile}
          onClick={() => navigate('/dashboard')}
          sx={{ mr: { xs: 0, md: 3 } }}
        >
          {membershipPending?.dashboard_button_label}
        </Button>
        <Button
          data-cy="pages-ProgramRegistrationPage-Guide-GuideRegistrationPublishedPage_learn-button"
          variant="outlined"
          color="info"
          size="large"
          sx={{
            mt: { xs: 2, md: 0 },
          }}
          fullWidth={isMobile}
          onClick={() => navigate('/learn')}
        >
          {baseLanguage.globals?.common?.explore_learning_hub}
        </Button>
      </Box>
    </Paper>
  );
};
