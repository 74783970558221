// external
import { Box, Divider, Paper, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import {
  EProgramVariation,
  ICustomField,
  IMembership,
  IProfile,
  ISkill,
  ProgramRegistrationSkills,
} from '@guider-global/shared-types';
import { LoadingButton } from '@mui/lab';
import { PageCard, Skeleton } from 'components';
import { RegistrationLimitReachedModal } from 'modals';
// Hooks

import {
  useCustomFields,
  useLocalization,
  useMatches,
  useMemberships,
  useMobileMediaQuery,
  useProfiles,
  useRelationships,
  useSkills,
  useTrackEvent,
} from 'hooks';

// Store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideNavbar } from 'store/slices/appSlice';
import {
  IMembershipForm,
  selectMembershipTraineeForm,
  setMembershipTraineeForm,
} from 'store/slices/formsSlice';
// utils
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  renderFields,
  renderSkills,
} from 'pages/ProgramRegistrationPage/utils';
import { areArraysEqual } from 'utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';

export const TraineeGroupJoinPage: React.FC = () => {
  const [isFormReady, setIsFormReady] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Organization
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Base language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { getProfiles } = useProfiles({ getSilently: true });
  const [profile] = getProfiles();
  const profileId = profile.id;

  const { isLoadingMemberships, reqMemberships, memberships } = useMemberships(
    {},
  );

  const { relationships: getRelationships } = useRelationships({});
  const relationships = getRelationships();

  const { isLoadingSkills } = useSkills({});

  const { reqCustomFields, isLoadingCustomFields } = useCustomFields({});

  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const { reqMatches, isLoadingMatches } = useMatches({});

  const membershipTraineeForm = useAppSelector((state) =>
    selectMembershipTraineeForm(state, programSlug),
  );

  useTrackEvent({
    track: {
      eventKey: 'program-trainee-registration-visited',
      eventParams: {
        organization: { organizationSlug },
        program: {
          organizationSlug,
          programSlug,
        },
      },
    },
    for: programSlug,
  });

  const programMemberships: IMembership[] = memberships().filter(
    (membership) => membership.programSlug === programSlug,
  );

  const traineeMembership: IMembership | undefined = programMemberships.find(
    (membership) => membership.role === 'trainee',
  );

  const { programs } = useOrganizationPrograms({ organizationSlug });
  const program = programs.find(
    (program) => program.metadata.id.current === programSlug,
  );

  const skillsConfig = useMemo(
    () => program?.registration?.skills as ProgramRegistrationSkills,
    [program],
  );

  const skillsOptions = useMemo(
    () => skillsConfig?.options?.map((skill) => skill.id.current) ?? [],
    [skillsConfig],
  );

  const membershipProgramFields = useMemo(
    () => (traineeMembership?.programFields as ICustomField[]) ?? [],
    [traineeMembership],
  );

  const defaultFormFields = useMemo(() => {
    const programSkills =
      (traineeMembership?.skills as Partial<ISkill>[]) ?? [];

    return {
      ...(programSkills.length !== 0 && {
        skills: programSkills
          .map((skill) => skill.fieldSlug)
          .filter(
            (skillValue) => skillValue && skillsOptions.includes(skillValue),
          ),
      }),
      ...Object.fromEntries(
        membershipProgramFields.map((customField) => [
          customField.fieldSlug,
          customField.value,
        ]),
      ),
    };
  }, [membershipProgramFields, traineeMembership, skillsOptions]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: membershipTraineeForm || defaultFormFields,
  });
  const typedControl: Control = control as unknown as Control;

  useEffect(() => {
    if (!isFormReady && Object.keys(defaultFormFields).length !== 0) {
      reset(defaultFormFields);

      setTimeout(() => {
        trigger();
      }, 1);

      setIsFormReady(true);
    }
  }, [isFormReady, defaultFormFields, reset, trigger]);

  useEffect(() => {
    dispatch(hideNavbar(true));
  }, [dispatch]);

  const handleRegistrationLimitReachedModalAction = useCallback(() => {
    navigate('/relationships');
  }, [navigate]);

  if (!program) {
    return <></>;
  }

  const isGroupProgram =
    program.program_details?.program_variation === EProgramVariation.Group;
  const programType = program.program_details?.program_type;
  const programTypeText = programType?.program_type_text;
  const programVariation = programTypeText?.variations?.individual;
  const programRelationshipLimits = program.relationships.relationship_limits;
  const isTraineeRelationshipLimitSet =
    programRelationshipLimits?.enable_trainee_relationship_limit;
  const traineeRelationshipLimit = isTraineeRelationshipLimitSet
    ? programRelationshipLimits?.trainee_relationship_limit
    : undefined;
  const programRegistrationTraineeGuideLimitModal =
    programTypeText?.variations?.individual?.registration?.registration_trainee
      ?.registration_trainee_guide_limit_modal;
  const programRelationships = relationships.filter((relationship) => {
    const relationshipTraineeProfileIds = (
      relationship.traineeProfiles as IProfile[]
    ).map((relationship) => relationship.id);
    const relationshipIncludesTrainee =
      relationshipTraineeProfileIds.includes(profileId);
    return (
      relationship.programSlug === programSlug &&
      !relationship.isConcluded &&
      relationshipIncludesTrainee
    );
  });

  const registrationQuestions =
    program?.registration?.registration_questions?.filter(
      (registrationQuestion) =>
        ['trainee', 'both'].includes(registrationQuestion.audience),
    );

  const showRegistrationLimitReachedModal =
    traineeRelationshipLimit !== undefined
      ? programRelationships.length >= traineeRelationshipLimit
      : false;

  const isSkillsEnabled = program?.registration?.skills?.enable_skills_question;

  const publishMembership = async (data: IMembershipForm) => {
    let customFields: ICustomField[] = [];

    const customFieldsPostData: Partial<ICustomField>[] = Object.entries(data)
      .filter(([fieldKey]) => fieldKey !== 'skills')
      .map(([fieldKey, fieldValue]) => {
        const fieldType = program?.registration?.registration_questions?.find(
          (registrationQuestion) =>
            registrationQuestion.id.current === fieldKey,
        )?.type;

        return {
          fieldSlug: fieldKey,
          organizationSlug: organizationSlug,
          programSlug: programSlug,
          fieldType,
          value: fieldValue,
          profileId,
        };
      });

    if (customFieldsPostData && customFieldsPostData.length !== 0) {
      const customFieldsResponse = await reqCustomFields({
        method: 'POST',
        url: '/customfields',
        data: customFieldsPostData,
      });

      Array.isArray(customFieldsResponse.data) &&
        (customFields = customFieldsResponse.data);
    }

    const customFieldIds: string[] | undefined = customFields.map(
      (customField) => customField.id,
    );

    const membershipData = {
      role: 'trainee' as 'trainee',
      organizationSlug,
      programSlug,
      profile: profileId,
      isPublished: true,
      programFields: customFieldIds ?? [],
      skillSlugs: data.skills ?? [],
      programVariationTypeSlug: EProgramVariation.Group,
    };

    await reqMemberships({
      method: 'POST',
      url: '/memberships',
      data: membershipData,
    });

    await Promise.all([
      reqMemberships({ url: '/memberships' }),
      reqMatches({
        url: `/matches?organizationSlug=${organizationSlug}&programSlug=${programSlug}`,
      }),
    ]);

    navigate('./choose');
  };

  const updateMembership: SubmitHandler<IMembershipForm> = async (data) => {
    let newCustomFieldIds: string[] = [];

    const { isPublished } = data;
    delete data.isPublished;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { skills, ...newCustomFields } = data;

    if (Object.keys(newCustomFields).length !== 0) {
      let customFieldsPostData: ICustomField[] = [];

      await Promise.all(
        Object.entries(newCustomFields).map(
          async ([newCustomFieldSlug, newCustomFieldValue]) => {
            const oldMembershipProgramField = membershipProgramFields.find(
              (membershipProgramField) =>
                membershipProgramField.fieldSlug === newCustomFieldSlug,
            );
            const oldValue = oldMembershipProgramField?.value;

            const isNew = !oldValue;

            if (!isNew) {
              let hasValueChanged = false;

              const fieldId = oldMembershipProgramField.id;
              const fieldType = oldMembershipProgramField.fieldType;

              const newValue = newCustomFieldValue;

              if (fieldType === 'multi-select') {
                if (Array.isArray(newValue) && Array.isArray(oldValue)) {
                  hasValueChanged = !areArraysEqual(newValue, oldValue);
                }
              } else {
                hasValueChanged = oldValue !== newValue;
              }

              if (!hasValueChanged) {
                return;
              }

              return await reqCustomFields({
                method: 'PATCH',
                url: `/customfields/${fieldId}`,
                data: {
                  value: newValue,
                  fieldType,
                },
              });
            } else {
              const currentRegistrationQuestion = registrationQuestions?.find(
                (registrationQuestion) =>
                  registrationQuestion.id.current === newCustomFieldSlug,
              );

              return customFieldsPostData.push({
                fieldSlug: newCustomFieldSlug,
                organizationSlug,
                fieldType: currentRegistrationQuestion?.type,
                value: newCustomFieldValue,
                profileId,
                programSlug,
              } as ICustomField);
            }
          },
        ),
      );

      if (customFieldsPostData?.length !== 0) {
        const { data: customFieldsResponseData } = await reqCustomFields({
          method: 'POST',
          url: '/customfields',
          data: customFieldsPostData,
        });

        if (!!customFieldsResponseData) {
          newCustomFieldIds = customFieldsResponseData.map(
            (customField) => customField.id,
          );
        }

        await reqCustomFields({ url: '/customfields' });
      }
    }

    const reqMembershipsData: Partial<IMembership> & { skillSlugs?: string[] } =
      {
        isPublished: !!isPublished,
        skillSlugs: data.skills,
        programSlug,
        ...(newCustomFieldIds && {
          programFields: [
            ...membershipProgramFields.map((field) => field.id),
            ...newCustomFieldIds,
          ],
        }),
      };

    await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${traineeMembership?.id}`,
      data: reqMembershipsData,
    });

    await Promise.all([
      reqMemberships({ url: '/memberships' }),
      reqMatches({
        url: `/matches?organizationSlug=${organizationSlug}&programSlug=${programSlug}`,
      }),
    ]);

    navigate('./choose');
  };

  const onSubmit: SubmitHandler<IMembershipForm> = async (data) => {
    dispatch(setMembershipTraineeForm({ programSlug, data }));

    if (traineeMembership) {
      await updateMembership(data);
    } else {
      await publishMembership(data);
    }
  };

  const renderForm = () =>
    program && (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: isMobile ? '0 16px' : '0 24px' }}
      >
        {isSkillsEnabled &&
          renderSkills({
            skillsConfig,
            typedControl,
            errors,
            pageName: 'TraineeRegistrationAboutYouPage',
            role: 'trainee',
            isGroupProgram,
          })}
        {renderFields({
          registrationQuestions,
          isSkillsEnabled,
          typedControl,
          errors,
          pageName: 'TraineeGroupJoinPage',
          role: 'trainee',
          isGroupProgram,
        })}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <LoadingButton
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid}
            sx={{
              color: isValid ? 'white' : 'inherit',
            }}
            fullWidth={isMobile}
            loading={
              isLoadingCustomFields() ||
              isLoadingSkills ||
              isLoadingMemberships() ||
              isLoadingMatches
            }
            data-cy={`pages_TraineeGroupJoinPage_continue-button`}
          >
            {baseLanguage?.globals?.common?.continue_button_label ?? 'Continue'}
          </LoadingButton>
        </Box>
      </form>
    );

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <RegistrationLimitReachedModal
        open={showRegistrationLimitReachedModal}
        title={
          programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_title ??
          ''
        }
        content={
          programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_description ??
          ''
        }
        action={{
          label:
            programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_button_label ??
            '',
          action: handleRegistrationLimitReachedModalAction,
          variant: 'contained',
          color: 'info',
        }}
      />
      <PageCard
        title={
          programVariation?.registration?.registration_trainee
            ?.registration_trainee_about_you
            ?.registration_trainee_about_you_title ?? ''
        }
        subtitle={
          programVariation?.registration?.registration_trainee
            ?.registration_trainee_about_you
            ?.registration_trainee_about_you_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      {!program ? <Skeleton /> : renderForm()}
    </Paper>
  );
};
