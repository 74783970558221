import { ProgressContainer } from 'containers';
import { useNavigate, useParams } from 'react-router-dom';
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import guideIcon from 'assets/users.svg';
import AvatarImage from 'assets/no_relationships_avatar.svg';
import {
  getFormattedProgramFields,
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  ICustomField,
  IMembership,
  ISkill,
  ProfileSkill,
  Program,
} from '@guider-global/shared-types';
import { OrganizationProfileView } from '@guider-global/ui';
import {
  AvatarBox,
  Button,
  CustomModal,
  Loading,
  TextStack,
} from '@guider-global/ui/lib/components';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { MDIcons, SanityIcon } from 'components';
import { useLocalization, useMemberships, useProfiles, useUsers } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';

export function ProgramPreviewPage() {
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const [showPublishedModal, setShowPublishedModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks

  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { getProfileImage } = useProfileImage();
  const dispatch = useDispatch();

  // Prefetch image
  useEffect(() => {
    const img = new Image();
    img.src = AvatarImage;
  }, []);

  // Organization

  const { organization, isLoadingOrganization } = useOrganization({
    organizationSlug,
  });

  // Profile
  const { getProfiles, isLoadingProfiles } = useProfiles({
    getSilently: false,
    forceRefresh: true,
  });
  const profile = getProfiles().at(0);

  // User
  const { users, isLoadingUsers } = useUsers({ getSilently: false });
  const user = users().at(0);

  // Program
  const { getProgram, isLoadingSanityPrograms } = useSanityOrganizationPrograms(
    {
      getSilently: false,
    },
  );
  const program = getProgram(programSlug);

  // Base Language

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Memberships
  const { memberships, isLoadingMemberships, reqMemberships } = useMemberships({
    getSilently: false,
    forceRefresh: true,
  });

  const membership = memberships().find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === 'guide',
  );

  async function handlePublish() {
    if (!membership || !program || !programSlug) return;

    if (membership?.requiresApproval === false) {
      navigate('/dashboard');
      return;
    }

    setLoading(true);

    const requiresApproval =
      program?.registration?.guide_membership_approval ?? false;

    const membershipsResult = await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${membership?.id}`,
      data: {
        programSlug,
        requiresApproval,
        isPublished: true,
      },
    });

    await reqMemberships({
      method: 'GET',
      url: `/memberships`,
    });

    if (membershipsResult?.status === 'success') {
      setShowPublishedModal(true);
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: membershipsResult?.message,
          timeout: 5000,
        }),
      );
    }
    setLoading(false);
  }

  const customFields = getFormattedProgramFields(
    membership as IMembership,
    program as Program,
  );

  const userImage =
    getProfileImage({
      profilePicture: profile?.picture,
      userPicture: user?.picture,
    }) ?? '';

  const profileFieldsData =
    getVisibleProfileFields(
      organization?.profile_fields,
      profile?.organizationFields as ICustomField[],
    ) ?? [];

  const profileFields = profileFieldsData?.map((field) => {
    return {
      icon: <SanityIcon name={field?.icon?.name as MDIcons} />,
      label: field?.label?.toString() ?? '',
    };
  });

  const skills =
    (membership?.skills as ISkill[])?.map((skill, index) => {
      const allSkills = [
        ...(program?.registration?.skills?.soft_skills ?? []),
        ...(program?.registration?.skills?.hard_skills ?? []),
      ] as ProfileSkill[];

      let label =
        allSkills?.find((s) => s?.slug === skill?.fieldSlug)?.label ??
        undefined;

      if (label === '' || !label) {
        label =
          program?.registration?.skills?.options?.find(
            (s) => s?.id?.current === skill?.fieldSlug,
          )?.label ?? undefined;
      }

      return {
        key: `${label}-${index}`,
        isMatched: false,
        value: label ?? '',
      };
    }) ?? [];

  const goalsSource =
    program?.registration?.goals?.custom_goal_categories ??
    organization?.goal_categories?.categories;

  const goals =
    membership?.goalCategories?.map((goalCategory, index) => {
      const label = goalsSource?.find(
        (g) => g?.goal_category_slug?.current === goalCategory?.fieldSlug,
      )?.goal_category_name;

      return {
        key: `${label}-${index}`,
        isMatched: false,
        value: label ?? '',
      };
    }) ?? [];

  const isLoading =
    isLoadingMemberships() ||
    isLoadingProfiles() ||
    isLoadingUsers() ||
    isLoadingSanityPrograms() ||
    isLoadingOrganization;

  if (!baseLanguage)
    return (
      <ProgressContainer percentage={100}>
        <></>
      </ProgressContainer>
    );

  if (isLoading && !loading)
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        percentage={100}
      >
        <Loading color="secondary" />
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={100}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '832px' }}
        pt={{ xs: 4, md: 2 }}
      >
        <AvatarBox
          orientation="vertical"
          textSpacing={1.5}
          size="medium"
          heading={{
            text: program?.program_details?.program_type?.program_type_text
              ?.variations?.individual?.registration?.registration_guide
              ?.registration_guide_review_profile
              ?.registration_guide_review_profile_title,
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: program?.program_details?.program_type?.program_type_text
                ?.variations?.individual?.registration?.registration_guide
                ?.registration_guide_review_profile
                ?.registration_guide_review_profile_description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
          avatarSrc={guideIcon}
          avatarSx={{
            p: 1.25,
            border: 'none',
            backgroundColor: '#F5F5F5',
          }}
        />

        <OrganizationProfileView
          customFields={customFields}
          profileFields={profileFields}
          guideReviews={{
            reviewsPlural: baseLanguage?.profile_card?.reviews_plural,
            reviewsSingular: baseLanguage?.profile_card?.reviews_singular,
            buttonLabel: baseLanguage?.profile_card?.reviews_button_label,
            noReviewsText: baseLanguage?.profile_card?.no_reviews_yet_label,
            reviews: [],
          }}
          displayName={profile?.displayName ?? ''}
          subtitles={[
            {
              text: profile?.jobTitle ?? '',
              variant: 'subtitle1',
            },
            {
              text: profile?.townOrCity ?? '',
              variant: 'caption',
              color: 'text.secondary',
            },
          ]}
          avatarSrc={userImage}
          skills={{
            title:
              program?.registration?.skills?.guide?.question_heading ??
              program?.registration?.skills?.text?.input_label ??
              'Skills',
            skills,
          }}
          goals={{
            title: baseLanguage?.globals?.goals?.goals,
            goals,
          }}
          linkedInUrl={profile?.linkedInUrl}
          viewLinkedInLabel={
            baseLanguage?.programs?.program_registration
              ?.linkedin_profile_button_label
          }
          buttons={[
            {
              label:
                membership?.requiresApproval === undefined
                  ? program?.program_details?.program_type?.program_type_text
                      ?.variations?.individual?.registration?.registration_guide
                      ?.registration_guide_review_profile
                      ?.registration_guide_review_profile_publish_profile_button_label
                  : baseLanguage?.globals?.common?.save_changes_button_label,
              key: '1',
              variant: 'contained',
              color: 'info',
              sx: { px: 3 },
              onClick: handlePublish,
              loading,
            },
          ]}
        />
      </Stack>
      <CustomModal isOpen={showPublishedModal}>
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={5}
          py={4}
          width={'380px'}
        >
          <TextStack
            align="center"
            size="large"
            heading={
              membership?.requiresApproval
                ? program?.program_details?.program_type?.program_type_text
                    ?.variations?.individual?.registration?.registration_guide
                    ?.registration_guide_membership_pending?.title
                : program?.program_details?.program_type?.program_type_text
                    ?.variations?.individual?.registration?.registration_guide
                    ?.registration_guide_complete
                    ?.registration_guide_complete_title
            }
            subtitles={[
              {
                text: membership?.requiresApproval
                  ? program?.program_details?.program_type?.program_type_text
                      ?.variations?.individual?.registration?.registration_guide
                      ?.registration_guide_membership_pending?.description
                  : program?.program_details?.program_type?.program_type_text
                      ?.variations?.individual?.registration?.registration_guide
                      ?.registration_guide_complete
                      ?.registration_guide_complete_description,
                variant: 'subtitle1',
                color: 'text.secondary',
                textAlign: 'center',
              },
            ]}
          />
          <img
            src={AvatarImage}
            width={'70%'}
            alt="Group of people"
            loading="eager"
          />
          <Button
            fullWidth
            color="info"
            variant="contained"
            onClick={() => navigate('/dashboard')}
            label={
              membership?.requiresApproval
                ? program?.program_details?.program_type?.program_type_text
                    ?.variations?.individual?.registration?.registration_guide
                    ?.registration_guide_membership_pending
                    ?.dashboard_button_label
                : program?.program_details?.program_type?.program_type_text
                    ?.variations?.individual?.registration?.registration_guide
                    ?.registration_guide_complete
                    ?.registration_guide_complete_dashboard_button_label
            }
          />
        </Stack>
      </CustomModal>
    </ProgressContainer>
  );
}
